/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Footer from "./footer"
import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      allStrapiMasterParametros {
        edges {
          node {
            id
            key
            nombre
            strapiId
            valor
            master_parametro {
              id
              key
              nombre
              valor
            }
          }
        }
      }
    }
  `)

  const parametros = data.allStrapiMasterParametros.edges.map(x => x.node)


  return (
    <>
      <Header />
      <main>{children}</main>
      <Footer parametros={parametros} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
