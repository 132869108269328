import React, { useState } from "react"
import LogoAgendaBlue from "../images/logo-agenda-blue.svg"
import { navigate } from "@reach/router"
import { useForm } from "../hooks/useForm"
import { Link } from "gatsby"

export const Header = ({ searchTextParam }) => {
  const initialForm = {
    searchText: searchTextParam ? searchTextParam : "",
  }

  const [formValues, handleInputChange] = useForm(initialForm)
  const { searchText } = formValues

  const [searchButton, setSearchButton] = useState(false)

  const handleClick = () => {
    setSearchButton(s => !s)
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (searchText.trim())
      navigate(`/buscar`, { state: { searchText: searchText } })
  }
  return (
    <div
      data-collapse="medium"
      data-animation="default"
      data-duration="400"
      role="banner"
      className="navbar w-nav"
    >
      <div className="container-1200 top-menu w-container">
        <div className="div-50 logo-menu">
          <Link to="/home" className="brand-logo w-nav-brand new-button">
            <img
              src={LogoAgendaBlue}
              loading="lazy"
              alt="Agenda PUCP web link"
            />
          </Link>
        </div>

        <div className="div-50 no-rel w-clearfix">
          <div className="dive-hide-lg">
            <button
              data-w-id="8f73afc4-e0d7-066d-0924-cf6099ffca81"
              className="btn-search trigger w-button new-button"
              onClick={handleClick}
            >
              Button  Text
            </button>
          </div>

          <form
            onSubmit={handleSubmit}
            className="search-top w-form"
            style={
              searchButton
                ? {
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    transition: "all 300ms ease",
                  }
                : {}
            }
          >
            <input
              type="text"
              className="search-top-menu w-input"
              maxLength="256"
              placeholder="Buscar por palabra clave"
              id="search"
              name="searchText"
              value={searchText}
              onChange={handleInputChange}
            />
            <button
              type="submit"
              value="Buscar"
              className="btn-search w-button"
            >
              Buscar
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Header
